import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAppData } from 'src/utils/useApp';

const savedData = getAppData();

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  initialPage: any,
  isFiltersVisible: boolean;
  isSearchVisible: boolean;
  isDashboardEditable: boolean;
  isOpenSubMenu: any;
  isOpenMoreMenu: any;
  isOpenSetupScreen: boolean;
  isOpenWelcomeScreen: boolean;
  isAutoOpenWelcomeScreen: boolean;
  isUserSettingsLoaded: boolean;
  containerHeight: any;
  currentMenu: any;
  cypressCurrentTag: any;
  cookies: any;
  isModalFullscreen: any;
  reRender: any;
  sideContent: any;
  theme: any;
  urlParams: any;
};

const initialState: ArrayModel = {
  initialPage: null,
  isFiltersVisible: false,
  isSearchVisible: false,
  isDashboardEditable: false,
  isOpenSubMenu: false,
  isOpenMoreMenu: false,
  isOpenSetupScreen: false,
  isOpenWelcomeScreen: false,
  isAutoOpenWelcomeScreen: false,
  isUserSettingsLoaded: false,
  containerHeight: 0,
  currentMenu: "main",
  cypressCurrentTag: undefined,
  cookies: savedData.cookies ? savedData.cookies : [],
  isModalFullscreen: false,
  reRender: 0,
  sideContent: null,
  theme: "light",
  urlParams: {},
};

const slice = createSlice({
  name: 'layout',
  initialState: initialState,
  reducers: {
    setInitialPage(state, action: PayloadAction<boolean>){
      state.initialPage = action.payload;
    },
    setIsFiltersVisible(state, action: PayloadAction<boolean>){
      state.isFiltersVisible = action.payload;
    },
    setIsSearchVisible(state, action: PayloadAction<boolean>){
      state.isSearchVisible = action.payload;
    },
    setIsDashboardEditable(state, action: PayloadAction<boolean>){
      state.isDashboardEditable = action.payload;
    },
    setIsOpenSubMenu(state, action: PayloadAction<Model>){
      state.isOpenSubMenu = action.payload;
    },
    setIsOpenMoreMenu(state, action: PayloadAction<Model>){
      state.isOpenMoreMenu = action.payload;
    },
    setIsOpenSetupScreen(state, action: PayloadAction<boolean>){
      state.isOpenSetupScreen = action.payload;
    },
    setIsOpenWelcomeScreen(state, action: PayloadAction<boolean>){
      state.isOpenWelcomeScreen = action.payload;
    },
    setIsAutoOpenWelcomeScreen(state, action: PayloadAction<boolean>){
      state.isAutoOpenWelcomeScreen = action.payload;
    },
    setIsUserSettingsLoaded(state, action: PayloadAction<boolean>) {
      state.isUserSettingsLoaded = action.payload;
    },
    setContainerHeight(state, action: PayloadAction<Model>){
      state.containerHeight = action.payload;
    },
    setCurrentMenu(state, action: PayloadAction<Model>){
      state.currentMenu = action.payload;
    },
    setCypressCurrentTag(state, action: PayloadAction<Model>){
      state.cypressCurrentTag = action.payload;
    },
    setCookies(state, action: PayloadAction<Model>){
      state.cookies = action.payload;
    },
    setIsModalFullscreen(state, action: PayloadAction<Model>){
      state.isModalFullscreen = action.payload;
    },
    setReRender(state, action: PayloadAction<Model>){
      state.reRender = action.payload;
    },
    setSideContent(state, action: PayloadAction<Model>){
      state.sideContent = action.payload;
    },
    setTheme(state, action: PayloadAction<Model>){
      state.theme = action.payload;
    },
    setUrlParams(state, action: PayloadAction<Model>){
      state.urlParams = action.payload;
    },
  }
});

export default slice;