import React from 'react';
import { CKEditor } from 'ckeditor4-react';
import { createUseStyles } from 'react-jss';
import { getAppEnvironmentApiData } from 'src/utils/useApp';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useState, useStates } from '../../../utils/useState';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
    '& .cke': {
      borderRadius: '10px',
      overflow: 'hidden',
    },
  },
}));

type RichTextEditorType = {
  uid: any;
  name?: any;
  value?: any;
  label?: any;
  onKeyUp?: any;
  onChange?: any;
  onFocus?: any;
  onInputEnd?: any;
  useName?: boolean;
  inputDelay?: number;
  inputNoDelayOnEmpty?: boolean;
  onDialogChange?: any;
  disabled?: boolean;
  customRef?: any;
};

const RichTextEditor: React.FunctionComponent<RichTextEditorType> = ({uid, name, label, value, onKeyUp, onChange, onFocus, onDialogChange, onInputEnd, useName = false, inputDelay = 3000, inputNoDelayOnEmpty, disabled, customRef}) => {

  const classes = useStyles();
  const languageData = useAppSelector((state: any) => state.language);
  const adminUrl = getAppEnvironmentApiData().adminUrl;

  const [state, setState] = useStates({
    configuration: {},
    isReady: false,
  });

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleChangeValue = (e: any) => {
    if(onChange) {
      if(useName) {
        if(onKeyUp) {
          onKeyUp(name, e.editor.getData(), e, {current: e.editor});
        }
        onChange(name, e.editor.getData(), e, {current: e.editor});
      } else {
        if(onKeyUp) {
          onKeyUp(name, e.editor.getData(), e, {current: e.editor});
        }
        onChange(e.editor.getData(), e, {current: e.editor});
      }
    }
    if(debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const debouncetimeout = setTimeout(() => {
      if(onInputEnd) {
        if(useName) {
          onInputEnd(name, e.editor.getData(), e, {current: e.editor});
        } else {
          onInputEnd(e.editor.getData(), e, {current: e.editor});
        }
      }
    }, inputNoDelayOnEmpty ? (e.target.value.length === 0 ? 1 : inputDelay) : inputDelay);
    setDebounceTimeout(debouncetimeout);
  };

  const init = (instance: any) => {
    instance.plugins.addExternal('bbcode', adminUrl + 'js/plugins/ckeditor/plugins/bbcode/');
    instance.on( 'dialogDefinition', (e: any) => {
      const dialogName = e.data.name;
      const dialogDefinition = e.data.definition;
      dialogDefinition.dialog.on('show', () => {
        onDialogChange(true);
      });
      dialogDefinition.dialog.on('hide', () => {
        onDialogChange(false);
      });
      if(dialogName === 'link' ) {
        const infoTab = dialogDefinition.getContents( 'info' );
        let linktypeField = infoTab.get('linkType');
        linktypeField['items'] = [
          linktypeField['items'][0],
          linktypeField['items'][2],
          linktypeField['items'][3]
        ];
        /*
        let protocolField = infoTab.get( 'protocol' );
          protocolField['items'] = [
            protocolField['items'][0],
            protocolField['items'][1]
          ];
        */
      }
    });
    instance.on('instanceReady', (e: any) => {
      e.editor.on('paste', () => {
        setTimeout(function(){
          e.editor.setData(e.editor.getData().replaceAll(/\[img\].*\[\/img\]/ig, '').trim());
        }, 100);
      });
      if(onKeyUp) {
        e.editor.on('keyup', () => {
          if(useName) {
            onKeyUp(name, e.editor.getData(), e, {current: e.editor});
          } else {
            onKeyUp(e.editor.getData(), e, {current: e.editor});
          }
        });
      }
    });
  };

  const handleFocus = (e: any) => {
    if(onFocus) {
      onFocus(name, e.editor.getData(), e, {current: e.editor});
    }
  };

  useEffect(() => {
    let config = state.configuration;
    config.extraPlugins = 'bbcode,colorbutton';
    config.clipboard_handleImages = false;
    config.pasteFromWordRemoveFontStyles = true;
    config.pasteFromWordRemoveStyles = true;
    config.pasteFromWord_inlineImages = false;
    config.toolbarGroups = [
      {name: 'document', groups: ['mode', 'document', 'doctools']},
      {name: 'clipboard', groups: ['clipboard', 'undo']},
      {name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing']},
      {name: 'forms', groups: ['forms']},
      {name: 'basicstyles', groups: ['basicstyles', 'cleanup']},
      {name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph']},
      {name: 'links', groups: ['links']},
      {name: 'insert', groups: ['insert']},
      {name: 'styles', groups: ['styles']},
      {name: 'colors', groups: ['colors']},
      {name: 'tools', groups: ['tools']},
      {name: 'others', groups: ['others']},
      {name: 'about', groups: ['about']}
    ];
    config.removeButtons = 'Save,Templates,NewPage,ExportPdf,Preview,Print,PasteFromWord,PasteText,Paste,Copy,Cut,Replace,Find,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,Subscript,Superscript,CopyFormatting,Outdent,Blockquote,JustifyLeft,BidiLtr,BidiRtl,JustifyCenter,CreateDiv,JustifyRight,Language,JustifyBlock,Indent,Image,Table,Anchor,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,FontSize,Font,Format,Styles,BGColor,ShowBlocks,About';
    config.language = languageData.language.toLowerCase();
    config.extraAllowedContent = 'a[!href,document-href]';
    setState("configuration", config); 
    setState("isReady", true);  
  }, [languageData.language, setState, state.configuration], []);

  return state.isReady ? (
    <div className={classes.wrapper}>
      {
        label ? (
          <label>
            {label}
          </label>
        ) : null
      }
      <CKEditor
        id={uid}
        name={uid}
        initData={value}
        readOnly={disabled}
        config={state.configuration}
        onChange={handleChangeValue}
        onFocus={handleFocus}
        onBeforeLoad={init}
      />
    </div>
  ) : null;
};

export default RichTextEditor;