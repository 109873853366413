import AttachmentModal from '../../components/Modals/AttachmentModal';
import CalendarEventHandleModal from 'src/components/Modals/CalendarEventHandleModal';
import CalendarFiltersModal from 'src/components/Modals/CalendarFiltersModal';
import CalendarGalleryModal from 'src/components/Modals/CalendarGalleryModal';
import CalendarSettingsModal from 'src/components/Modals/CalendarSettingsModal';
import CameraModal from 'src/components/Modals/CameraModal';
import ChildCardModal from '../../components/Modals/ChildCardModal';
import ChildDetailModal from 'src/components/Modals/ChildDetailModal';
import ChildrenSelect from '../../components/Selects/ChildrenSelect';
import ClassbookDetailModal from '../../components/Modals/ClassbookDetailModal';
import ClassbookHandleModal from '../../components/Modals/ClassbookHandleModal';
import ClassDetailModal from '../../components/Modals/ClassDetailModal';
import ClubDetailModal from 'src/components/Modals/ClubDetailModal';
import CommunicationChangeThreadNameModal from 'src/components/Modals/CommunicationChangeThreadNameModal';
import CommunicationGalleryModal from 'src/components/Modals/CommunicationGalleryModal';
import CommunicationMessageViewedModal from 'src/components/Modals/CommunicationMessageViewedModal';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import CookiesModal from 'src/components/Modals/CookiesModal';
import DashboardHandleWidgetModal from 'src/components/Modals/DashboardHandleWidget';
import DetailModal from '../../components/Modals/DetailModal';
import DiagnosticsModal from '../../components/Modals/DiagnosticsModal';
import DraftsModal from 'src/components/Modals/DraftsModal';
import EmployeeDetailModal from '../../components/Modals/EmployeeDetailModal';
import ExcuseNotesDetailModal from '../../components/Modals/ExcuseNotesDetailModal';
import ExcuseNotesHandleModal from '../../components/Modals/ExcuseNotesHandleModal';
import FileInputModal from 'src/components/Modals/FileInputModal';
import GalleryModal from '../../components/Modals/GalleryModal';
import ImageModal from '../../components/Modals/ImageModal';
import InfoModal from '../../components/Modals/InfoModal';
import ItemPickerModal from 'src/components/Modals/ItemPickerModal';
import LanguageModal from 'src/components/Modals/LanguageModal';
import PhotoChangeModal from '../../components/Modals/PhotoChangeModal';
import PopupGalleryModal from 'src/components/Modals/PopupGalleryModal';
import PostCreateModal from '../../components/Modals/PostCreateModal';
import PostDetailModal from '../../components/Modals/PostDetailModal';
import PressReportTemplateHandleModal from 'src/components/Modals/PressReportTemplateHandleModal';
import PropTypes from 'prop-types';
import React from 'react';
import ReactionDetailModal from 'src/components/Modals/ReactionDetailModal';
import SchoolUrlsModal from 'src/components/Modals/SchoolUrlsModal';
import ShopGalleryModal from 'src/components/Modals/ShopGalleryModal';
import ShopItemDetailModal from 'src/components/Modals/ShopItemDetailModal';
import ShopItemVariantModal from 'src/components/Modals/ShopItemVariantModal';
import ShopOrderDetailModal from 'src/components/Modals/ShopOrderDetailModal';
import ShopOrderStatusModal from 'src/components/Modals/ShopOrderStatusModal';
import ShoppingCartModal from 'src/components/Modals/ShoppingCartModal';
import SSOGetModal from 'src/components/Modals/SSOGetModal';
import SSOLoginQrCodeModal from 'src/components/Modals/SSOLoginQrCodeModal';
import StockCategoryDetailModal from 'src/components/Modals/StockCategoryDetailModal';
import StockCategoryHandleModal from '../../components/Modals/StockCategoryHandleModal';
import StockItemDetailModal from 'src/components/Modals/StockItemDetailModal';
import StockItemHandleModal from 'src/components/Modals/StockItemHandleModal';
import StockTagDetailModal from 'src/components/Modals/StockTagDetailModal';
import StockTagHandleModal from 'src/components/Modals/StockTagHandleModal';
import SubstitutionsDetailModal from '../../components/Modals/SubstitutionsDetailModal';
import SubstitutionsHandleModal from '../../components/Modals/SubstitutionsHandleModal';
import TimelineCommentDetailModal from 'src/components/Modals/TimelineCommentDetailModal';
import TimelineGalleryModal from '../../components/Modals/TimelineGalleryModal';
import TimelinePollDetailModal from '../../components/Modals/TimelinePollDetailModal';
import TimetableHourDetailModal from 'src/components/Modals/TimetableHourDetailModal';
import TimetableHourHandleModal from 'src/components/Modals/TimetableHourHandleModal';
import TimetableRoomDetailModal from '../../components/Modals/TimetableRoomDetailModal';
import TimetableRoomHandleModal from '../../components/Modals/TimetableRoomHandleModal';
import TimetableSubjectDetailModal from '../../components/Modals/TimetableSubjectDetailModal';
import TimetableSubjectHandleModal from '../../components/Modals/TimetableSubjectHandleModal';
import WageDetailModal from '../../components/Modals/WageDetailModal';
import WarningModal from '../../components/Modals/WarningModal';
import { resetAllModals } from '../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { useEffect } from '../../utils/useEffect';
import { useLocation } from 'react-router-dom';

interface Props {
  children: any;
}

const ModalsController: React.FunctionComponent<Props> = (props:Props) => {

  const location = useLocation();
  const modalsData = useAppSelector((state: any) => state.modals);
  const dispatch = useAppDispatch(); 
  
  useEffect(() => {
    dispatch(resetAllModals());
  }, [location, dispatch], [location]);

  return (
    <>
      {props.children} 
      {
        modalsData.imageModal.isOpen ? (
          <ImageModal/>
        ) : null
      }
      {
        modalsData.attachmentModal.isOpen ? (
          <AttachmentModal/>
        ) : null
      }
      {
        modalsData.childrenModal.isOpen ? (
          <ChildrenSelect
            isModalOpen={true}
            isInModal={true}
            modalTitle={modalsData.childrenModal.modalTitle}
            modalOnClose={modalsData.childrenModal.modalOnClose}
            isSelectAll={false}
            isMultipleSelect={false}
            displayMode={modalsData.childrenModal.displayMode}
            defaultClasses={modalsData.childrenModal.defaultClasses}
            defaultClassesData={modalsData.childrenModal.defaultClassesData}
            defaultChildren={modalsData.childrenModal.defaultChildren}
            defaultChildrenData={modalsData.childrenModal.defaultChildrenData}
            mode={modalsData.childrenModal.mode}
            isChildModal={true}
            isAllowArchived={modalsData.childrenModal.isAllowArchived}
            isAllowArchivedToggle={modalsData.childrenModal.isAllowArchived}
            modalAllowChildrenCount={false}
            isShowChildrenViews={modalsData.childrenModal.isShowChildrenViews}
            isShowChildrenArchived={modalsData.childrenModal.isShowChildrenArchived}
            isDisableTotalCount={true}
            isAllowSearch={true}
            modalAllowCancel={true}
            modalAllowClose={true}
          />
        ) : null
      }
      {
        modalsData.timelineGalleryModal.isOpen ? (
          <TimelineGalleryModal/>
        ) : null
      }
      {
        modalsData.shopGalleryModal.isOpen ? (
          <ShopGalleryModal/>
        ) : null
      }
      {
        modalsData.calendarGalleryModal.isOpen ? (
          <CalendarGalleryModal/>
        ) : null
      }
      {
        modalsData.timelinePollDetailModal.isOpen ? (
          <TimelinePollDetailModal/>
        ) : null
      }
      {
        modalsData.timelineCommentDetailModal.isOpen ? (
          <TimelineCommentDetailModal/>
        ) : null
      }
      {
        modalsData.reactionDetailModal.isOpen ? (
          <ReactionDetailModal/>
        ) : null
      }
      {
        modalsData.galleryModal.isOpen ? (
          <GalleryModal/>
        ) : null
      }
      {
        modalsData.employeeModal.isOpen ? (
          <EmployeeDetailModal/>
        ) : null
      }
      {
        modalsData.childCardModal.isOpen ? (
          <ChildCardModal/>
        ) : null
      }
      {
        modalsData.childDetailModal.isOpen ? (
          <ChildDetailModal/>
        ) : null
      }
      {
        modalsData.postDetailModal.isOpen ? (
          <PostDetailModal/>
        ) : null
      }
      {
        modalsData.classModal.isOpen ? (
          <ClassDetailModal/>
        ) : null
      }
      {
        modalsData.classbookDetailModal.isOpen ? (
          <ClassbookDetailModal/>
        ) : null
      }
      {
        modalsData.classbookHandleModal.isOpen ? (
          <ClassbookHandleModal/>
        ) : null
      }
      {
        modalsData.pressReportTemplateHandleModal.isOpen ? (
          <PressReportTemplateHandleModal/>
        ) : null
      }
      {
        modalsData.clubDetailModal.isOpen ? (
          <ClubDetailModal/>
        ) : null
      }
      {
        modalsData.calendarEventHandleModal.isOpen ? (
          <CalendarEventHandleModal/>
        ) : null
      }
      {
        modalsData.calendarFiltersModal.isOpen ? (
          <CalendarFiltersModal/>
        ) : null
      }
      {
        modalsData.calendarSettingsModal.isOpen ? (
          <CalendarSettingsModal/>
        ) : null
      }
      {
        modalsData.communicationGalleryModal.isOpen ? (
          <CommunicationGalleryModal/>
        ) : null
      }
      {
        modalsData.communicationChangeThreadNameModal.isOpen ? (
          <CommunicationChangeThreadNameModal/>
        ) : null
      }
      {
        modalsData.communicationMessageViewedModal.isOpen ? (
          <CommunicationMessageViewedModal/>
        ) : null
      }
      {
        modalsData.excuseNotesDetailModal.isOpen ? (
          <ExcuseNotesDetailModal/>
        ) : null
      }
      {
        modalsData.excuseNotesHandleModal.isOpen ? (
          <ExcuseNotesHandleModal/>
        ) : null
      }
      {
        modalsData.fileInputModal.isOpen ? (
          <FileInputModal/>
        ) : null
      }
      {
        modalsData.popupGalleryModal.isOpen ? (
          <PopupGalleryModal/>
        ) : null
      }
      {
        modalsData.shopItemDetailModal.isOpen ? (
          <ShopItemDetailModal/>
        ) : null
      }
      {
        modalsData.shopItemVariantModal.isOpen ? (
          <ShopItemVariantModal/>
        ) : null
      }
      {
        modalsData.shopOrderDetailModal.isOpen ? (
          <ShopOrderDetailModal/>
        ) : null
      }
      {
        modalsData.shopOrderStatusModal.isOpen ? (
          <ShopOrderStatusModal/>
        ) : null
      }
      {
        modalsData.shoppingCartModal.isOpen ? (
          <ShoppingCartModal/>
        ) : null
      }
      {
        modalsData.schoolUrlsModal.isOpen ? (
          <SchoolUrlsModal/>
        ) : null
      }
      {
        modalsData.stockCategoryDetailModal.isOpen ? (
          <StockCategoryDetailModal/>
        ) : null
      }
      {
        modalsData.stockCategoryHandleModal.isOpen ? (
          <StockCategoryHandleModal/>
        ) : null
      }
      {
        modalsData.stockItemDetailModal.isOpen ? (
          <StockItemDetailModal/>
        ) : null
      }
      {
        modalsData.stockItemHandleModal.isOpen ? (
          <StockItemHandleModal/>
        ) : null
      }
      {
        modalsData.stockTagDetailModal.isOpen ? (
          <StockTagDetailModal/>
        ) : null
      }
      {
        modalsData.stockTagHandleModal.isOpen ? (
          <StockTagHandleModal/>
        ) : null
      }
      {
        modalsData.timetableHourDetailModal.isOpen ? (
          <TimetableHourDetailModal/>
        ) : null
      }
      {
        modalsData.timetableHourHandleModal.isOpen ? (
          <TimetableHourHandleModal/>
        ) : null
      }
      {
        modalsData.timetableSubjectDetailModal.isOpen ? (
          <TimetableSubjectDetailModal/>
        ) : null
      }
      {
        modalsData.timetableSubjectHandleModal.isOpen ? (
          <TimetableSubjectHandleModal/>
        ) : null
      }
      {
        modalsData.timetableRoomDetailModal.isOpen ? (
          <TimetableRoomDetailModal/>
        ) : null
      }
      {
        modalsData.timetableRoomHandleModal.isOpen ? (
          <TimetableRoomHandleModal/>
        ) : null
      }
      {
        modalsData.substitutionsDetailModal.isOpen ? (
          <SubstitutionsDetailModal/>
        ) : null
      }
      {
        modalsData.substitutionsHandleModal.isOpen ? (
          <SubstitutionsHandleModal/>
        ) : null
      }
      {
        modalsData.photoChangeModal.isOpen ? (
          <PhotoChangeModal/>
        ) : null
      }
      {
        modalsData.postCreateModal.isOpen ? (
          <PostCreateModal/>
        ) : null
      }
      {
        modalsData.cameraModal.isOpen ? (
          <CameraModal/>
        ) : null
      }
      {
        modalsData.diagnosticsModal.isOpen ? (
          <DiagnosticsModal/>
        ) : null
      }
      {
        modalsData.itemPickerModal.isOpen ? (
          <ItemPickerModal/>
        ) : null
      }
      {
        modalsData.wageDetailModal.isOpen ? (
          <WageDetailModal/>
        ) : null
      }
      {
        modalsData.dashboardHandleWidgetModal.isOpen ? (
          <DashboardHandleWidgetModal/>
        ) : null
      }
      {
        modalsData.draftsModal.isOpen ? (
          <DraftsModal/>
        ) : null
      }
      {
        modalsData.ssoGetModal.isOpen ? (
          <SSOGetModal/>
        ) : null
      }
      {
        modalsData.ssoLoginQrCodeModal.isOpen ? (
          <SSOLoginQrCodeModal/>
        ) : null
      }
      {
        modalsData.languageModal.isOpen ? (
          <LanguageModal/>
        ) : null
      }
      {
        modalsData.infoModal.isOpen ? (
          <InfoModal/>
        ) : null
      }
      {
        modalsData.confirmModal.isOpen ? (
          <ConfirmModal/>
        ) : null
      }
      {
        modalsData.detailModal.isOpen ? (
          <DetailModal/>
        ) : null
      }
      {
        modalsData.warningModal.isOpen ? (
          <WarningModal/>
        ) : null
      }
      {
        modalsData.cookiesModal.isOpen ? (
          <CookiesModal/>
        ) : null
      }
    </>
  );
};

ModalsController.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default ModalsController;