import ClubCard from 'src/components/Cards/ClubCard';
import Pagination from 'src/components/Layouts/Pagination';
import React, { useCallback, useRef } from 'react';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getFiltersKeys, getFiltersKeysArray, getQueryString } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { Navigate, useLocation } from 'react-router';
import { setClubFilterCurrentPage, setClubItems, setClubItemsCount } from 'src/store/actions/club.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';

const useStyles = createUseStyles((theme: any) => ({
  clubsPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    padding: '24px 24px',
    overflow: 'auto',
    maxHeight: '100%',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  clubsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '16px',
    height: 'fit-content',
    width: '100%',
    maxWidth: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
      height: '16px',
      width: '100%',
    },
  },
  clubCardWrapper: {
    width: 'calc(16.6666666667% - 16px)',
    [theme.breakpoints.down('xxxl')]: {
      width: 'calc(20% - 16px)',
    },
    [theme.breakpoints.down('xxl')]: {
      width: 'calc(25% - 16px)',
    },
    [theme.breakpoints.down('xl')]: {
      width: 'calc(33% - 8px)',
    },
    [theme.breakpoints.down('lg')]: {
      width: 'calc(33% - 12px)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(50% - 8px)',
      borderRadius: "0px",
      '& > div': {
        borderRadius: "0px",
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    maxWidth: '100vw',
    borderRadius: '24px',
    display: 'flex',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
  notFound: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flex: '1 1 auto',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    gap: '4px',
    '& > span': {
      fontWeight: '600',
      fontSize: '14px',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      '& > span': {
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        fontSize: '12px',
        [theme.breakpoints.down('md')]: {
          fontSize: '10px',
        },
        '& > span': {
          display: 'block',
          width: '8px',
          height: '8px',
          borderRadius: '100%',
        },
      },
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const clubData = useAppSelector((state: any) => state.club);
  const dataData = useAppSelector((state: any) => state.data);
  const filtersData = useAppSelector((state: any) => state.filters);
  const userData = useAppSelector((state: any) => state.user);
  const location = useLocation();
  const clubsService = useAppSelector((state: any) => state.services).clubsService;

  const queryString = getQueryString(location);

  const userAccess = userData.userAccess;
  const userAccessSchools = userData.userAccessSchools;

  const clubSchoolList = dataData.schools.filter((item: any) => userAccessSchools.club.includes(item.schoolID));
  const limit = 16;

  const isLoading = useRef(false);

  const [state, setState] = useStates({
    isFirstTime: true,
    isLoading: true,
    lastFilterParams: {},
  });

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["clubs", "club_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {'applicationState': 'clubApplicationState', 'state': 'clubState', 'enrollmentAvailable': 'clubEnrollmentAvailable'});
      await saveUserSettings(dispatch, userData, "filters", ["clubs"], filtersParams);
    }
  }, [dispatch, userData]);

  const getClubs = useCallback((page: any) => {
    setState("isLoading", true);
    const filtersParams = getFiltersKeys(filtersData.filterParams, {'clubApplicationState': 'applicationState', 'clubState': 'state', 'clubEnrollmentAvailable': 'enrollmentAvailable'});
    const getFiltersParams = {...filtersParams, page: page, limit: limit};
    if(JSON.stringify(state.lastFilterParams) === JSON.stringify(getFiltersParams)) return;
    isLoading.current = true;
    setState("lastFilterParams", getFiltersParams);
    clubsService && clubsService.listClubs(getFiltersParams).then((result: any) => {
      if(result) {
        if(result.data) {
          dispatch(setClubItems(result.data.items));
          dispatch(setClubItemsCount(result.data.countItems));
          setState("isLoading", false);
          isLoading.current = false;
        } else {
          createNotification(t("club_not_loaded"), "error");
          setState("isLoading", false);
          isLoading.current = false;
        }
      } else {
        createNotification(t("club_not_loaded"), "error");
        setState("isLoading", false);
        isLoading.current = false;
      }
    }).catch(() => {
      createNotification(t("club_not_loaded"), "error");
      setState("isLoading", false);
      isLoading.current = false;
    });
  }, [t, clubsService, setState, dispatch, filtersData.filterParams, state.lastFilterParams]);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && isLoading.current === false) {
      getClubs(1);
    }
  }, [filtersData.filterParams, dispatch, getClubs, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams, filtersData.isFilterParamsLoaded]);
  
  useEffect(() => {
    getClubs(1);
    return () => {
      dispatch(setClubItems([]));
      dispatch(setClubItemsCount(0));
    };
  }, [dispatch, getClubs], []);

  const onPaginationChange = (page: any) => {
    dispatch(setClubFilterCurrentPage(page));
    getClubs(page);
  };

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: false,
        isAllowedPostType: false,
        isAllowedGalleryType: false,
        isAllowedActivityType: false,
        isAllowedChildID: true,
        isAllowedEmployeeID: false,
        isAllowedDate: false,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: clubSchoolList.length > 1,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: false,
        isAllowedGalleryHide: false,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
        isAllowedClubApplicationState: true,
        isAllowedClubState: true,
        isAllowedClubEnrollmentAvailable: true,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    } 
  }, [dispatch, clubSchoolList, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded, filtersData.isFilterParamsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["clubs", "club_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["clubs"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            if(!userData.membership.active) return;
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      } else {
        dispatch(setFilterParams({clubState: "active"}));
      }
      dispatch(setIsFilterParamsLoaded(true));
    }
  }, [dispatch, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded, saveFilters, userData.membership.active], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);


  useEffect(() => {
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return !userAccess.club ? (
    <Navigate to={`/403${queryString}`} state={{ from: location }}/>
  ) : (
    <>
      {
        !userAccess.subscription ? (
          <SubscriptionBanner service="club" isInCenter={true}/>
        ) : (
          <>
            <div className={classes.clubsPage}>
              <div className={classes.clubsList}>
                {
                  state.isLoading ? (
                    <div className={classes.loading}>
                      <CircularProgress className={classes.spinner}/>
                    </div>
                  ) : (
                    <>
                      {
                        clubData.items.length > 0 ? clubData.items.filter((item: any) => (getUserRole(userData.userObject.roleType) === "parent" && item.visibleForParent) || getUserRole(userData.userObject.roleType) !== "parent").map((data: any, key: any) => (
                          <div className={classes.clubCardWrapper} key={`k_${key}`}>
                            <ClubCard data={data}/>
                          </div>
                        )) : (
                          <div className={classes.notFound}>
                            <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                            <span>{t('no_results')}</span>
                            <p>{t('no_results_found')}</p>
                          </div>
                        )    
                      }
                      <Pagination currentPage={clubData.filterCurrentPage} total={clubData.itemsCount} limit={limit} disabled={state.isLoading} onChange={onPaginationChange}/>
                    </>
                  )
                }
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default PageTemplate;