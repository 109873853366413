const filters = [
  {
    key: 'timeline',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    items: [],
  },
  {
    key: 'finance',
    roles: [
      "parent",
    ],
    items: [],
  },
  {
    key: 'children',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    items: [],
  },
  {
    key: 'contacts',
    roles: [
      "parent",
    ],
    items: [],
  },
  {
    key: 'employees',
    roles: [
      "director",
    ],
    items: [],
  },
  {
    key: 'gallery',
    roles: [
      "parent",
    ],
    items: [
      {
        key: "galleryTypeID",
        value: ["photo", "video"],
      }
    ],
  },
  {
    key: 'classbook',
    roles: [
      "teacher",
      "director",
    ],
    items: [],
  },
  {
    key: 'calendar',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    items: [],
  },
  {
    key: 'clubs',
    roles: [
      "parent",
      "teacher",
      "director",
    ],
    items: [
      {
        key: "clubState",
        value: "active",
      }
    ],
  },
];

export default filters;