import React from 'react';
import RenderOption from './RenderOption';
import RenderOptionGroup from './RenderOptionGroup';
import RenderSelect from './RenderSelect';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { createUseStyles } from 'react-jss';
import { getElementID } from '../../../utils/useUUID';
import { isCypress } from 'src/utils/useCypress';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  width: any;
};

const useStyles = createUseStyles((theme: any) => ({
  selectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: (props: Props) => props.width + 'px',
    flex: (props: Props) => '0 0 ' + props.width + 'px',
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
}));

type SelectType = {
  id?: any;
  label?: any;
  items: any;
  selected: any;
  setSelected: any;
  hasChildren?: any;
  inputLabel?: any;
  inputIcon?: any;
  disabled?: boolean;
  readonly?: boolean;
  isMultiple?: boolean;
  width?: number;
  defaultTitle?: any;
  defaultChildTitle?: any;
  defaultSelected?: any;
  useTranslate?: any;
  allowClear?: boolean;
  className?: any;
  dataCy?: any;
};

const Select: React.FunctionComponent<SelectType> = ({id = getElementID(), label, items, selected = null, setSelected, inputLabel, inputIcon, disabled = false, readonly = false, isMultiple = false, width = 230, defaultTitle = "name", defaultChildTitle = "text", defaultSelected, useTranslate = false, allowClear = true, className, dataCy}) => {

  const { t } = useTranslation();

  const [state, setState] = useStates({
    selectedItem: selected,
    isOpen: false,
    isDisabled: !disabled ? (items ? items.length === 0 : true) : disabled,
  });

  const classes = useStyles({
    width: width,
  });

  useEffect(() => {
    if(JSON.stringify(selected) !== JSON.stringify(state.selectedItem)) {
      setState("selectedItem", selected);
    }
  }, [selected, setState, state.selectedItem], [selected]);

  useEffect(() => {
    setState("isDisabled", !disabled ? items.length === 0 : disabled);
  }, [setState, items, disabled], [items, disabled]);

  const handleOpen = () => {
    setState("isOpen", !state.isOpen);
  };

  const handleClickAway = () => {
    setState("isOpen", false);
  };

  const handleSelected = (value: any) => {
    setState("selectedItem", value);
    setSelected(value);
  };

  if(!dataCy) dataCy = id;
  
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={`${classes.selectWrapper} ${className ? className : null}`} onClick={handleOpen} data-cy={isCypress() ? `${dataCy}_select_wrapper` : null}>
        {
          label ? (
            <label htmlFor={id} data-cy={isCypress() ? `${dataCy}_select_label` : null}>
              {label}
            </label>
          ) : null
        }
        <RenderSelect id={id} items={items} isMultiple={isMultiple} disabled={state.isDisabled} readonly={readonly} selectedItem={selected} setSelected={handleSelected} inputLabel={inputLabel} inputIcon={inputIcon} defaultTitle={defaultTitle} defaultSelected={defaultSelected} useTranslate={useTranslate} allowClear={allowClear} isOpen={state.isOpen} dataCy={dataCy}>
          {
            items ? items.map((item: any, key: any) => item.children ? (
              <RenderOptionGroup label={useTranslate ? t(item[defaultTitle]) : item[defaultTitle]} item={item[defaultTitle]} key={`k_${key}`} dataCy={dataCy}>
                {
                  item.children.map((childItem: any, key: any) => (
                    <RenderOption id={`${dataCy}i${key}`} isMultiple={isMultiple} selected={selected} value={childItem} item={childItem[defaultChildTitle]} defaultSelected={defaultSelected} key={`k_${key}`} dataCy={dataCy}>
                      {useTranslate ? t(childItem[defaultChildTitle]) : childItem[defaultChildTitle]}
                    </RenderOption>
                  ))
                }
              </RenderOptionGroup>
            ) : (
              <RenderOption id={`${dataCy}i${key}`} isMultiple={isMultiple} selected={selected} value={item} item={item[defaultTitle]} defaultSelected={defaultSelected} key={`k_${key}`} dataCy={dataCy}>
                {useTranslate ? t(item[defaultTitle]) : item[defaultTitle]}
              </RenderOption>  
            )) : null
          }
        </RenderSelect>
      </div>
    </ClickAwayListener>
  );
}

export default Select;