import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Model {
  [key: string]: any;
};

interface ArrayModel {
  items: any;
  itemsCount: any;
  filterCurrentPage: any;
};

const initialState: ArrayModel = {
  items: [],
  itemsCount: 0,
  filterCurrentPage: 1,
};

const slice = createSlice({
    name: 'club',
    initialState: initialState,
    reducers: {
      setClubItems(state, action: PayloadAction<Model>){
        state.items = action.payload;
      },
      setClubItemsCount(state, action: PayloadAction<Model>){
        state.itemsCount = action.payload;
      },
      setClubFilterCurrentPage(state, action: PayloadAction<Model>){
        state.filterCurrentPage = action.payload;
      },
    }
});

export default slice;