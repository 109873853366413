import AttendanceProgramInfoItem from 'src/components/Items/AttendanceProgramInfoItem';
import AuthenticatedImage from '../../Items/AuthenticatedImage';
import Button from '@mui/material/Button';
import Clamp from 'react-multiline-clamp';
import DateFormat from '../../../utils/dateFormat';
import htmlParse from 'html-react-parser';
import IconButton from 'src/components/Buttons/IconButton';
import React, { useCallback } from 'react';
import ScrollMenu from 'src/components/Menus/ScrollMenu';
import SVG from '../../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getQueryString, getSchoolSettings, moveLastToFirstInArray } from 'src/utils/useFunctions';
import { getUserRole, getUserSetting } from '../../../utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { setClassModal, setDiagnosticsModal } from '../../../store/actions/modals.actions';
import { setFilterParams } from '../../../store/actions/filters.actions';
import { setIsFiltersVisible } from '../../../store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { weekdaysMin } from 'src/constants/weekdays';

const useStyles = createUseStyles((theme: any) => ({
  childCard: {
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.08)",
    maxWidth: '100vw',
    position: 'relative',
    overflow: 'hidden',
  },
  childHeader: {
    display: 'flex',
    width: '100%',
    height: '188px',
    position: 'relative',
    backgroundColor: theme.colors.primaryBlue[200],
    color: theme.colors.black,
    borderRadius: '24px 24px 0 0',
  },
  childImage: {
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    backgroundColor: theme.colors.white,
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundImage: 'linear-gradient(to bottom,rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.5) 90%)',
    },
  },
  nameBlock: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 28px',
    maxWidth: '50%',
    color: theme.colors.alwaysWhite,
  },
  displayName: {
    fontSize: '24px',
    fontWeight: 'bold',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fullName: {
    fontSize: '14px',
    fontWeigth: '300',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  otherBlock: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 28px',
    maxWidth: '50%',
    color: theme.colors.alwaysWhite,
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      height: '44px',
      width: '1px',
      backgroundColor: theme.colors.white,
    },
  },
  age: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 'bold',
    '& > p': {
      fontSize: '14px',
      lineHeight: '14px',
      marginBottom: '0',
      marginLeft: '4px',
      paddingBottom: '1px',
    },
  },
  birthday: {
    fontSize: '14px',
    fontWeigth: '300',
  },
  infoBlock: {
    padding: '0px 18px 22px 18px',
    backgroundColor: theme.colors.white,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '16px',
    padding: '22px 10px 10px 10px',
    fontWeight: 'bold',
    maxWidth: '100%',
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },
    '& > p': {
      padding: '0',
      marginBottom: '0',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }, 
    '&:empty': {
      padding: '10px 0px 0px 0px',
      '& + button': {
        '&::after': {
          display: 'none',
        },
      },
    }
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '16px',
    padding: '10px 10px',
    marginTop: '12px',
    fontWeight: 'bold',
    cursor: 'pointer',
    borderRadius: '12px',
    position: 'relative',
    width: '100%',
    textTransform: 'none',
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1px',
      width: '90%',
      backgroundColor: '#F2F4FA',
    },
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
      '&:last-of-type': {
        marginRight: '0px',
        marginLeft: 'auto',
        width: '24px',
        height: '24px',
      },
    },
    '& > span': {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      '& > em': {
        color: theme.colors.white,
        fontStyle: 'normal',
        marginLeft: 'auto',
        borderRadius: '50%',
        backgroundColor: theme.colors.systemRed[500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        fontSize: '10px',
        lineHeight: '10px',
      },
    },    
  },
  bookmark: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[700],
    fontSize: '16px',
    padding: '10px 10px',
    marginTop: '12px',
    fontWeight: 'bold',
    position: 'relative',
    '&::after': {
      content: `''`,
      position: 'absolute',
      top: '-6px',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1px',
      width: '90%',
      backgroundColor: '#F2F4FA',
    },
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },    
  },
  classesWrapper: {
    display: 'flex',
    width: '100%',
  },
  classList: {
    display: 'flex',
    maxWidth: '100%',
    padding: '10px 0',
    overflow: 'auto',
    gap: '8px',
  },
  classBadge: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[300],
    borderRadius: '50px',
    padding: '6px 12px',
    whiteSpace: 'nowrap',
    color: theme.colors.grey[700],
    fontWeight: '500',
    fontSize: '14px',
    cursor: 'pointer',
  },
  notes: {
    marginTop: '16px',
    display: 'block',
    color: theme.colors.grey[650],
    fontSize: '14px',
    padding: '0px 10px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& a': {
      color: theme.colors.primaryBlue[500],
      textDecoration: 'underline',
      whiteSpace: 'nowrap',
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
  showToggleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '0.5rem',
    backgroundColor: theme.colors.white,
    fontSize: '12px',
  },
  showToggle: {
    color: theme.colors.primaryBlue[500],
    cursor: "pointer",
    fontWeight: 'bold',
    textTransform: 'uppercase',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  schoolWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    paddingTop: '8px',
    backgroundColor: theme.colors.white,
  },
  schoolHeader: {
    display: 'flex',
    width: '100%',
    height: '188px',
    position: 'relative',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.black,
  }, 
  schoolImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    '& > div': {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.colors.alwaysWhite,
    },
  },
  schoolBadge: {
    position: 'absolute',
    top: '18px',
    right: '0',
    width: '115px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0,133,255,0.7)',
    color: theme.colors.white,
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '11px 24px 9px 15px',
    borderRadius: '6px 0px 0px 6px',
    '& > svg': {
      marginRight: '9px',
      width: '24px',
      height: '24px',
    },
  },
  schoolBlock: {
    padding: '0px 28px 22px 28px',
  },
  schoolName: {
    display: 'flex',
    color: '#2D3142',
    fontSize: '22px',
    padding: '22px 0px 10px 0px',
    fontWeight: 'bold',
    '& > svg': {
      marginRight: '6px',
      width: '24px',
      height: '24px',
    },  
  },
  schoolDescription: {
    fontSize: '14px',
    color: theme.colors.grey[650],
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& a': {
      color: theme.colors.primaryBlue[500],
      textDecoration: 'underline',
      transition: 'color 0.25s',
      '&:hover': {
        color: theme.colors.primaryBlue[600],
      },
    },
  },
  editLink: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '14px',
    marginLeft: 'auto',
    paddingLeft: '0.5rem',
    marginRight: '0px',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
    '&:only-child': {
      marginLeft: 'unset',
    },
  },
  representativesList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 0px 0px',
    maxWidth: '100%',  
  },
  personItem: {
    display: 'flex',
    width: '100%',
    padding: '0px 10px 19px 10px',
  },
  personInfo: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      color: theme.colors.grey[650],
      fontSize: '14px',
      fontWeight: '500',
      marginBottom: '0px',
    },
    '& > span': {
      color: theme.colors.grey[700],
      fontSize: '14px',
      fontWeight: 'bold',
    },
  },
  phone: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    transition: 'color 0.25s',
    '&:hover': {
      color: theme.colors.primaryBlue[600],
    },
  },
  callButton: {
    marginRight: '0px',
    marginLeft: 'auto',
    width: '28px',
    height: '28px',
    cursor: 'pointer',
    backgroundColor: theme.colors.primaryBlue[500],
    '&:hover': {
      backgroundColor: theme.colors.primaryBlue[600],
    },
    '& svg': {
      color: theme.colors.white,
      width: '16px',
      height: '16px',
      transform: 'scale(2)',
    },
  },
  attendanceProgram: {
    width: '100%',
    marginBottom: '12px',
    position: 'relative',
    '&::after': {
      content: `''`,
      position: 'absolute',
      bottom: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '1px',
      width: '90%',
      backgroundColor: '#F2F4FA',
    },
    '& > div': {
      display: 'flex',
      width: '100%',    
    },
    '& > p': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '0.5rem',
      fontSize: '0.8rem',
      padding: '0 10px',
      color: theme.colors.grey[650],
      gap: '0.5rem',
      '& > span:first-of-type': {
        fontWeight: '500',
      },
      '& > span:last-of-type': {
        fontWeight: '400',
        whiteSpace: 'nowrap',
      },
    },
  },
  attendanceCell: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'center',
    color: theme.colors.grey[650], 
    '& > span': {
      marginBottom: '8px',
    },
  },
}));

type ItemsType = {
  childID: any;
  disableExcuseNotes?: boolean;
  disableSubstitutions?: boolean;
  disableFinance?: boolean;
  disableTimeline?: boolean;
  disableCalendar?: boolean;
  disableDiagnostics?: boolean;
  disableSchool?: boolean;
  disableContacts?: boolean;
};

const ChildCard: React.FunctionComponent<ItemsType> = ({childID, disableExcuseNotes, disableSubstitutions, disableFinance, disableTimeline, disableCalendar, disableDiagnostics, disableSchool, disableContacts}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const queryString = getQueryString(location);

  const getChildData = useCallback((childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? null : dataData.children.find((item: any) => item.childID === childID);
  }, [dataData.children]);

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const childData = getChildData(childID);

  const schoolSettings = userData.schoolSettings;

  const firstDayInWeek = getUserSetting(userData.userSettings, "customizations", ["calendar", "first_day_in_week"]);

  const weekdays: any = firstDayInWeek === 0 ? moveLastToFirstInArray(weekdaysMin) : weekdaysMin;

  const attendanceProgram = childData ? (childData.attendanceProgram ? childData.attendanceProgram : []) : [];

  const childAttendanceDays: any = (attendanceProgram && attendanceProgram !== null && attendanceProgram.days) ? attendanceProgram.days.filter((day: any) => day.day === 6 || day.day === 7).length > 0 ? [1,2,3,4,5,6,7] : [1,2,3,4,5] : [];

  const attendanceDays: any = (firstDayInWeek === 0 && childAttendanceDays[childAttendanceDays.length - 1] === 7) ? moveLastToFirstInArray(childAttendanceDays) : childAttendanceDays;
  
  const isSchoolSubstitutionsEnabled = childData ? (getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions.enable : false) : false;

  const handleEditLink = () => {
    window.open(childData.editLink); 
  };
  
  const handleClickExcuseNotes = () => {
    navigate(`/excuse-notes${queryString}`);
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickSubstitutions = () => {
    navigate(`/substitutions${queryString}`);
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickFinance = () => {
    navigate(`/finance${queryString}`);
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleClickPosts = () => {
    navigate(`/timeline${queryString}`);
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };
  
  const handleClickCalendar = () => {
    navigate(`/calendar${queryString}`);
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickContacts = (schoolID: any) => {
    navigate(`/contacts${queryString}`);
    dispatch(setFilterParams({schoolID: [schoolID]}));
    setTimeout(() => {
      dispatch(setFilterParams({schoolID: [schoolID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleClickDiagnostics = () => {
    const settings = {
      isOpen: true,
      childID: childData.childID,
    };
    dispatch(setDiagnosticsModal(settings));
  };

  const handleClickClassDetail = (classID: any) => {
    const settings = {
      isOpen: true,
      classID: classID,
    };
    dispatch(setClassModal(settings));
  };

  const handleCallButton = (phone: any) => {
    window.location.href = 'tel:' + phone; 
  };

  return childData ? (
    <div className={classes.childCard} data-cy={isCypress() ? 'childCard' + childData.childID : null}>
      <div className={classes.childHeader}>
        <AuthenticatedImage className={classes.childImage} thumbLink={childData.photo.thumbLink} fullsizeLink={childData.photo.fullsizeLink} preferQuality="fullsize"/>
        <div className={classes.nameBlock}>
          <span className={classes.displayName} data-cy={isCypress() ? 'childDisplayName' : null}>{childData.displayName}</span>
          <span className={classes.fullName} data-cy={isCypress() ? 'childFullName' : null}>{childData.firstname + ' ' + childData.surname}</span> 
        </div>
        {
          childData.birthday ? (
            <div className={classes.otherBlock}>
              <span className={classes.age} data-cy={isCypress() ? 'childAge' : null}>
                {childData.age}
                <p>
                {
                  childData.age === 1 ? (t('year')).toLowerCase() : (childData.age === 2 || childData.age === 3 || childData.age === 4) ? (t('years')).toLowerCase() : (t('years_more')).toLowerCase() 
                }
                </p>
              </span>
              <span className={classes.birthday} data-cy={isCypress() ? 'childBirthday' : null}>
                {
                  DateFormat(childData.birthday, "default", languageData, t)
                }
              </span> 
            </div>
          ) : null
        }
      </div>
      <div className={classes.infoBlock}>
        {
          attendanceDays.length > 0 ? (
            <div className={classes.attendanceProgram} data-cy={isCypress() ? 'childAttendanceProgram' : null}>
              <span className={classes.title}>
                <SVG src="clock"/>
                <p>{t('attendance_programme')}</p>
                {
                  (childData.editLink && childData.editLink !== "") ? (
                    <span className={classes.editLink} onClick={handleEditLink} data-cy={isCypress() ? 'childEditLink' : null}>{t('edit_child')}</span>
                  ) : null
                }
              </span>
              <p>
                <span data-cy={isCypress() ? 'childAttendanceProgramName' : null}>
                  {
                    attendanceProgram.name
                  }
                </span>
                <span data-cy={isCypress() ? 'childAttendanceProgramPrice' : null}>
                  {
                    attendanceProgram.price
                  }
                </span>
              </p>
              <div>
                {
                  attendanceDays.map((day: any, key: any) => {
                    const startDay = firstDayInWeek === 0 ? day : day - 1;
                    const theDay = startDay === 7 ? 0 : startDay;
                    return (
                      <div className={classes.attendanceCell} key={`k_${key}`}>
                        <span>{t(weekdays[theDay][0].toLowerCase() + weekdays[theDay].slice(1))}.</span>
                        <AttendanceProgramInfoItem days={attendanceProgram.days.filter((item: any) => item.day === day).length} day={attendanceProgram.days.find((item: any) => item.day === day)}/>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          ) : null
        }
        {
          childData.classesID.length > 0 ? (
            <span className={classes.title}>
              <SVG src="school-cap-outlined"/>
              {childData.classesID.length === 1 ? t('class') : t('classes')}
              {
                (childData.editLink && childData.editLink !== "" && (!attendanceProgram || attendanceProgram === null)) ? (
                  <span className={classes.editLink} onClick={handleEditLink} data-cy={isCypress() ? 'childEditLink' : null}>{t('edit_child')}</span>
                ) : null
              }
            </span>
          ) : (
            <span className={classes.title}>
              {
                (childData.editLink && childData.editLink !== "" && (!attendanceProgram || attendanceProgram === null)) ? (
                  <span className={classes.editLink} onClick={handleEditLink} data-cy={isCypress() ? 'childEditLink' : null}>{t('edit_child')}</span>
                ) : null
              }
            </span>
          )
        }
        {
          childData.classesID.length > 0 ? (
            <div className={classes.classesWrapper} data-cy={isCypress() ? 'childClasses' : null}>
              <ScrollMenu className={classes.classList} layout="horizontal">
              {
                childData.classesID.map((classID: any, key: any) => dataData.classes.filter((classData: any) => classData.classID === classID).length !== 0 ? (
                  <span className={classes.classBadge} key={`k_${key}`} onClick={() => handleClickClassDetail(classID)} data-cy={isCypress() ? 'childClass' + classID : null}>
                    {htmlParse(getClassData(classID).name)}
                  </span> 
                ) : null)
              }
              </ScrollMenu>
            </div>
          ) : null
        }
        {
          (!childData.isArchived && childData.isInAnyActiveClass && !disableExcuseNotes) ? (
            <Button className={classes.link} onClick={handleClickExcuseNotes} data-cy={isCypress() ? 'childExcuseNotesButton' : null}>
              <SVG src="calendar-excuse-notes"/>
              <span>{t('excuse_notes')}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null
        }
        {
          (attendanceProgram && attendanceProgram !== null && !childData.isArchived && childData.isInAnyActiveClass && isSchoolSubstitutionsEnabled && !disableSubstitutions) ? (
            <Button className={classes.link} onClick={handleClickSubstitutions} data-cy={isCypress() ? 'childSubstitutionsButton' : null}>
              <SVG src="calendar-substitutions"/>
              <span>{t('substitutions')}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null
        }
        {
          (getUserRole(userData.userObject.roleType) === "parent" && childData.payment && childData.payment.totalCount > 0 && !disableFinance) ? (
            <Button className={classes.link} onClick={handleClickFinance} data-cy={isCypress() ? 'childFinanceButton' : null}>
              <SVG src="finance"/>
              <span>{t('finance')} {childData.payment.totalCountUnpaid !== 0 ? (<em>{childData.payment.totalCountUnpaid}</em>) : null}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null 
        }
        {
          !disableTimeline ? (
            <Button className={classes.link} onClick={handleClickPosts} data-cy={isCypress() ? 'childTimelineButton' : null}>
              <SVG src="timeline"/>
              <span>{t('posts')}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null
        }
        {
          (!childData.isArchived && childData.isInAnyActiveClass && !disableCalendar) ? (
            <Button className={classes.link} onClick={handleClickCalendar} data-cy={isCypress() ? 'childCalendarButton' : null}>
              <SVG src="calendar"/>
              <span>{t('calendar')}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null
        }
        {
          ((getUserRole(userData.userObject.roleType) === "teacherX" || getUserRole(userData.userObject.roleType) === "directorX") && !disableDiagnostics) ? (
            <Button className={classes.link} onClick={handleClickDiagnostics} data-cy={isCypress() ? 'childDiagnosticsButton' : null}>
              <SVG src="clipboard"/>
              <span>{t('diagnostics')}</span>
              <SVG src="chevron-right"/>
            </Button>
          ) : null
        }
        {
          (childData.representative && childData.representative.length > 0) ? (
            <span className={classes.bookmark} data-cy={isCypress() ? 'childRepresentatives' : null}>
              <SVG src="person"/>
              {t('representatives')}
            </span>
          ) : null
        }
        {
          (childData.representative && childData.representative.length > 0) ? (
            <div className={classes.representativesList}>
              {
                childData.representative.map((representative: any, key: any) => (
                  <div className={classes.personItem} key={`k_${key}`} data-cy={isCypress() ? 'childRepresentativePerson' : null}>
                    <div className={classes.personInfo}>
                      <p>
                        {
                          (representative.relationship && representative.relationship !== "" && representative.relationship !== "representative_relationship_") ? (
                            <span>{representative.relationship} &bull;</span>
                          ) : null
                        }
                        {
                          (representative.degreeBefore && representative.degreeBefore !== "") ? (
                            <span> {representative.degreeBefore}</span>
                          ) : null
                        }
                        {
                          (representative.firstname && representative.firstname !== "") ? (
                            <span> {representative.firstname}</span>
                          ) : null
                        }
                        {
                          (representative.surname && representative.surname !== "") ? (
                            <span> {representative.surname}</span>
                          ) : null
                        }
                        {
                          (representative.degreeAfter && representative.degreeAfter !== "") ? (
                            <span> {representative.degreeAfter}</span>
                          ) : null
                        }
                      </p>
                      {
                        (representative.numberIdCard && representative.numberIdCard !== "") ? (
                          <span>
                            {representative.numberIdCard}
                          </span>
                        ) : null
                      }
                      {
                        (representative.phone && representative.phone !== "") ? (
                          <span className={classes.phone} onClick={() => handleCallButton(representative.phone)}>
                            {representative.phone}
                          </span>
                        ) : null
                      }
                    </div>
                    {
                      (representative.phone && representative.phone !== "") ? (
                        <IconButton className={classes.callButton} onClick={() => handleCallButton(representative.phone)}>
                          <SVG src="phone"/>
                        </IconButton>
                      ) : null
                    }
                  </div>    
                ))
              }
            </div>
          ) : null
        }
        {
          (childData.representativePickup && childData.representativePickup.length > 0) ? (
            <span className={classes.bookmark} data-cy={isCypress() ? 'childRepresentativesPickup' : null}>
              <SVG src="person"/>
              {t('pickup_representatives')}
            </span>
          ) : null
        }
        {
          (childData.representativePickup && childData.representativePickup.length > 0) ? (
            <div className={classes.representativesList}>
              {
                childData.representativePickup.map((representative: any, key: any) => (
                  <div className={classes.personItem} key={`k_${key}`} data-cy={isCypress() ? 'childRepresentativePickupPerson' : null}>
                    <div className={classes.personInfo}>
                        <p>
                          {
                            (representative.relationship && representative.relationship) !== "" ? (
                              <span>{representative.relationship} &bull;</span>
                            ) : null
                          }
                          {
                            (representative.degreeBefore && representative.degreeBefore) !== "" ? (
                              <span> {representative.degreeBefore}</span>
                            ) : null
                          }
                          {
                            (representative.firstname && representative.firstname) !== "" ? (
                              <span> {representative.firstname}</span>
                            ) : null
                          }
                          {
                            (representative.surname && representative.surname) !== "" ? (
                              <span> {representative.surname}</span>
                            ) : null
                          }
                          {
                            (representative.degreeAfter && representative.degreeAfter) !== "" ? (
                              <span> {representative.degreeAfter}</span>
                            ) : null
                          }
                        </p>
                        {
                          (representative.numberIdCard && representative.numberIdCard) !== "" ? (
                            <span>
                              {representative.numberIdCard}
                            </span>
                          ) : null
                        }
                        {
                          (representative.phone && representative.phone !== "") ? (
                            <span className="phone" onClick={() => handleCallButton(representative.phone)}>
                              {representative.phone}
                            </span>
                          ) : null
                        }
                    </div>
                    {
                      (representative.phone && representative.phone !== "") ? (
                        <IconButton className={classes.callButton} onClick={() => handleCallButton(representative.phone)}>
                          <SVG src="phone"/>
                        </IconButton>
                      ) : null
                    }
                  </div>   
                ))
              }
            </div>
          ) : null
        }
        {
          childData.note ? (
            <span className={classes.bookmark} data-cy={isCypress() ? 'childOtherDetails' : null}>
              <SVG src="question-mark-circle"/>
              {t('other_info')}
            </span>
          ) : null
        }
        {
          childData.note ? (
            <Clamp
              lines={2}
              maxLines={100}
              withToggle
              showMoreElement={({ toggle }: {toggle: any}) => (
                <div className={classes.showToggleWrapper}>
                  <span className={classes.showToggle} onClick={toggle}>
                    {t('show_more')}
                  </span>
                </div>
              )}
              showLessElement={({ toggle }: {toggle: any}) => (
                <div className={classes.showToggleWrapper}>
                  <span className={classes.showToggle} onClick={toggle}>
                    {t('show_less')}
                  </span>
                </div>
              )}
            >
              <div className={classes.notes} data-cy={isCypress() ? 'childOtherNotes' : null}>
                {htmlParse(childData.note)}
              </div>
            </Clamp>
          ) : null
        }  
      </div>
      {
        !disableSchool && childData.schoolsID && childData.schoolsID.length > 0 && childData.schoolsID.map((theschool: any, key: any) => dataData.schools.filter((schoolData: any) => schoolData.schoolID === theschool).length > 0 ? (
          <div className={classes.schoolWrapper} key={`k_${key}`} data-cy={isCypress() ? 'childSchool' + theschool : null}>
            <div className={classes.schoolHeader}>
              <AuthenticatedImage className={classes.schoolImage} thumbLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === theschool).logo.thumbLink} fullsizeLink={dataData.schools.find((schoolData: any) => schoolData.schoolID === theschool).logo.fullsizeLink} preferQuality="fullsize"/>
              <span className={classes.schoolBadge}>
                <SVG src="school-cap-outlined"/>
                {t('school')}
              </span>
            </div>
            <div className={classes.schoolBlock}>
              <span className={classes.schoolName} data-cy={isCypress() ? 'childSchoolName' : null}>
                {htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === theschool).name)}
              </span>
              {
                dataData.schools.find((schoolData: any) => schoolData.schoolID === theschool)?.description ? (
                  <div className={classes.schoolDescription} data-cy={isCypress() ? 'childSchoolDescription' : null}>
                    {htmlParse(dataData.schools.find((schoolData: any) => schoolData.schoolID === theschool).description)}
                  </div>
                ) : null
              }
            </div>
            <div className={classes.infoBlock}>
              {
                (getUserRole(userData.userObject.roleType) === "parent" && dataData.employees.filter((employee: any) => employee.schoolID === theschool).length !== 0 && !disableContacts) ? (
                  <Button className={classes.link} onClick={() => handleClickContacts(theschool)} data-cy={isCypress() ? 'childSchoolContactsButton' : null}>
                    <SVG src="contacts"/>
                    <span>{t('contacts')}</span>
                    <SVG src="chevron-right"/>
                  </Button>
                ) : null 
              }            
            </div>
          </div>
        ) : null)
      }     
    </div>
  ) : null;
}

export default ChildCard;